<template lang="pug">
filter-template.client-filter(
  @reset="cleanFilter"
  @save="applyFilters"
  @cancel="cancel"
)
  template(#header) Компания заказчика
  template(#content)
    client-selector(fit-input-width v-model="filters.customerCompany")
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { useRoute } from "vue-router";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import ClientSelector from "@/components/selector/superAccount/TmCompanySelector.vue";

export default defineComponent({
  name: "ClientFilter",
  components: {
    ClientSelector,
    FilterTemplate,
  },
  props: {
    modelValue: {},
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  setup(props, { emit }) {

    const route = useRoute();
    const { modelValue } = toRefs(props);

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent(modelValue, false, ['customerCompany'], emit, route.name)

    return {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    }
  }
})
</script>

<style scoped lang="scss">
.client-filter {
  width: 320px;
}
</style>
